<template>
   <div class="row align-items-start">
      <div class="nav flex-sm-column nav-pills mx-auto px-sm-0 mb-1 mb-sm-0 justify-content-center" id="entrada-tab" role="tablist" aria-orientation="vertical">
			<button class="nav-link mb-1" :class="$route.query.view == 'Validar' ? 'active' : ''" id="nota-tab" data-bs-toggle="pill" data-bs-target="#nota" 
				type="button" role="tab" aria-controls="nota" :aria-selected="$route.query.view == 'Validar'">
				<span class="d-flex align-items-center">
					<i class="fal fa-file mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Nota</span>
				</span>
			</button>
			<button class="nav-link mb-1" @click="nota.lockedProd = false" :class="$route.query.view != 'Validar' ? 'active' : ''" id="boletos-tab" data-bs-toggle="pill" data-bs-target="#boletos" 
				type="button" role="tab" aria-controls="boletos" :aria-selected="$route.query.view != 'Validar'">
				<span class="d-flex align-items-center">
					<i class="fal fa-barcode mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Boletos</span>
				</span>
			</button>
			<button class="nav-link mb-1" @click="nota.lockedActions = false" id="produtos-tab" data-bs-toggle="pill" data-bs-target="#produtos" 
				type="button" role="tab" aria-controls="produtos" aria-selected="false" :disabled="nota.lockedProd">
				<span class="d-flex align-items-center">
					<i class="fal fa-carrot mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Produtos</span>
				</span>
			</button>
         <button class="nav-link mb-1 d-none d-sm-block" type="button" @click="baixarNF">
            <span class="d-flex align-items-center">
               <i class="fal fa-share mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Exportar</span>
            </span>
         </button>
			<button class="nav-link mb-1 border-theme" type="button" @click="validarNota" :disabled="nota.lockedActions"
				v-if="dadosUsuario.usuarioPermissoes.includes('Recebimento | Validar notas') && nota.status == 'Recebimento - Entregue' && $route.query.view == 'Validar'">
				<span class="d-flex align-items-center" :class="nota.lockedActions ? '' : 'color-theme'">
					<i class="fal fa-check mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Validar nota</span>
				</span>
			</button>
		</div>

		<div class="tab-content px-sm-0" id="entrada-tabContent">
			<div class="tab-pane fade" :class="$route.query.view == 'Validar' ? 'show active' : ''" id="nota" role="tabpanel" aria-labelledby="nota-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center">
									<div class="col">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-file color-theme font-13 me-2"></i>Nota</h6>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#boletos-tab')" class="badge badge-default bg-theme ms-1">
											<i class="far fa-long-arrow-right me-1 d-none d-sm-inline"></i> Avançar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<accordionNota :nota="nota" />
					</div>
				</div>
			</div>
			<div class="tab-pane fade" :class="$route.query.view != 'Validar' ? 'show active' : ''" id="boletos" role="tabpanel" aria-labelledby="boletos-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center">
									<div class="col">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-barcode color-theme font-13 me-2"></i>Boletos</h6>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#nota-tab')" class="badge badge-default bg-theme me-1">
											<i class="far fa-long-arrow-left me-1 d-none d-sm-inline"></i> Voltar
										</a>
										<a href="javascript:;" @click="mudarTab('#produtos-tab')" class="badge badge-default bg-theme ms-1">
											<i class="far fa-long-arrow-right me-1 d-none d-sm-inline"></i> Avançar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mb-1" v-if="$route.query.view == 'Entrada'">
						<div class="card mb-0">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-9 col-md-10 col-lg-11">
										<label><i class="far fa-barcode color-theme font-11 me-1"></i> Código do boleto</label>
										<input type="text" class="form-control" v-model="pesquisa.boleto" maxlength="200" v-focus @keyup.enter="setBipado(pesquisa.boleto, 'Boleto')" />
									</div>
									<div class="col-3 col-md-2 col-lg-1 align-self-end">
										<button type="button" class="btn btn-primary w-100" @click="setBipado(pesquisa.boleto, 'Boleto')"><i class="fas fa-search font-13"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="row">
							<boleto v-for="(boleto, index) in nota.boletos" :key="index" :boleto="boleto" :index="index" :showEdit="$route.query.view == 'Validar'" 
								@setBipado="setBipado($event, 'Boleto')" />
							<div class="col-12 my-5 text-center" v-if="nota.boletos.length == 0">
								<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum boleto encontrado</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="produtos" role="tabpanel" aria-labelledby="produtos-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row align-items-center justify-content-between justify-content-md-start">
									<div class="col-md mb-3 mt-1 my-md-0">
										<h6 class="mb-0 font-15 text-capitalize"><i class="far fa-carrot color-theme font-13 me-2"></i>Produtos</h6>
									</div>
									<div class="w-max-content" v-if="nota.status == 'Recebimento - Entregue' && nota.entradaEstoque == 'Recebimento'">
										<div class="row align-items-center justify-content-end">
											<div class="w-max-content pe-2"><i class="fal fa-box font-12"></i><span class="ms-1 d-none d-sm-inline"> Estoque</span></div>
											<div class="wpx-185">
												<v-select name="Desktop" class="w-100 select-sm" :options="nota.estoques" v-model="nota.idEstoque" :reduce="e => e.id" :clearable="false" :searchable="false"
													label="nome" placeholder="-" @input="trocarEstoque(nota.idEstoque)">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control select-sm" v-model="nota.idEstoque" @change="trocarEstoque(nota.idEstoque)">
													<option v-for="(option, index) in nota.estoques" :key="index" :value="option.id">{{ option.nome }}</option>
												</select>
											</div>
										</div>
									</div>
									<div class="w-max-content">
										<a href="javascript:;" @click="mudarTab('#boletos-tab')" class="badge badge-default bg-theme me-1">
											<i class="far fa-long-arrow-left me-1 d-none d-sm-inline"></i> Voltar
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 mb-1" v-if="$route.query.view == 'Entrada'">
						<div class="card mb-0">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-9 col-md-10 col-lg-11">
										<label><i class="far fa-barcode color-theme font-11 me-1"></i> Código do produto</label>
										<input type="text" class="form-control" v-model="pesquisa.produto" maxlength="200" v-focus @keyup.enter="setBipado(pesquisa.produto, 'Produto')" />
									</div>
									<div class="col-3 col-md-2 col-lg-1 align-self-end">
										<button type="button" class="btn btn-primary w-100" @click="setBipado(pesquisa.produto, 'Produto')"><i class="fas fa-search font-13"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
                  <div class="row">
                     <itemNota v-for="(produto, index) in nota.lstItensNotaEletronica" :key="index" :produto="produto" :index="index" :entradaEstoque="nota.entradaEstoque"
								:showEdit="$route.query.view == 'Validar' && nota.status == 'Recebimento - Entregue'" @setBipado="setBipado($event, 'Produto')" />
                     <div class="col-12 my-5 text-center" v-if="nota.lstItensNotaEletronica.length == 0">
								<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
							</div>
                  </div>
					</div>
				</div>
			</div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import boleto from '@/components/recebimento/Boleto.vue'
import itemNota from '@/components/recebimento/Produto.vue'
import accordionNota from '@/components/fiscal/notas/AccordionNota.vue'

export default {
   name: 'Nota',
	data: function () {
		return {
			pesquisa: {'nota': '', 'boleto': '', 'produto': ''},
			nota: {
				'id': null, 'chave': null, 'dataNota': {}, 'tipoEmissao': null, 'tipoOperacao': null, 'indConsumidor': null, 'indFinalidade': null, 'pis': null, 'cofins': null, 'valor': null, 
				'desconto': null, 'ipi': null, 'baseIcmsTrib': null, 'valorIcmsTrib': null, 'baseIcmsSt': null, 'valorIcmsSt': null, 'valorFrete': null, 'valorSeguro': null, 'cpfCnpjEmitente': null, 
				'nomeEmitente': null, 'razaoSocialEmitente': null, 'enderecoEmitente': null, 'numeroEnderecoEmitente': null, 'setorEmitente': null, 'cepEmitente': null, 'telefoneEmitente': null, 
				'inscricaoEstadualEmitente': null, 'estadoEmitente': null, 'xMunicipioEmitente': null, 'complementoEnderecoEmitente': null, 'regimeTributarioEmitente': null, 'entradaEstoque': null,
				'cpfCnpjDestinatario': null, 'nomeDestinatario': null, 'razaoSocialDestinatario': null, 'enderecoDestinatario': null, 'numeroEnderecoDestinatario': null, 'setorDestinatario': null, 
				'cepDestinatario': null, 'estadoDestinatario': null, 'telefoneDestinatario': null, 'inscricaoEstadualDestinatario': null, 'xMunicipioDestinatario': null, 'idEstoque': null,
				'complementoEnderecoDestinatario': null, 'emailDestinatario': null, 'status': null, 'lstItensNotaEletronica': [], 'pagamentos': [], 'boletos': [], 'naturezaOperacao': null, 
				'chaveReferenciada': null, 'dataFinalizado': null, 'dataRecebimento': null, 'numeroLoja': null, 'lockedProd': true, 'lockedActions': true
			}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
         this.buscarNota()
      }
	},
	components: {
		boleto, itemNota, accordionNota
	},
	methods: {
		mudarTab : function (tab) {
			$(tab).click()
		},
		baixarNF : function () {
			this.$store.dispatch('baixarNF', this.nota.chave)
		},
		trocarEstoque : function (estoque) {
			this.nota.lstItensNotaEletronica.forEach(p => p.idEstoque = estoque);
		},
		buscarNota : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'recebimento/getNota',
				params: {
					chave: this.$route.query.chaveNota
				}
			}).then(response => {
            let oldData = JSON.parse(JSON.stringify(this.tabs[this.$route.params.idTab].data.nota))
            let newData = response.data;
				newData.idEstoque = (oldData.idEstoque != null ? oldData.idEstoque : newData.idEstoque)
				newData.lockedProd = (oldData.lockedProd != true ? oldData.lockedProd : this.$route.query.view == 'Validar')
				newData.lockedActions = (oldData.lockedActions != true ? oldData.lockedActions : this.$route.query.view == 'Validar')
            
            oldData.lstItensNotaEletronica.forEach(oldItem => {
               newData.lstItensNotaEletronica.forEach(newItem => {
                  if (oldItem.id == newItem.id) {
                     newItem.qtd = oldItem.qtd;
                     newItem.validado = oldItem.validado;
							newItem.idEstoque = oldItem.idEstoque;
                  }
               });
            });

				oldData.boletos.forEach(oldItem => {
               newData.boletos.forEach(newItem => {
                  if (oldItem.id == newItem.id) {
                     newItem.validado = oldItem.validado;
                  }
               });
            });
            
            Object.assign(this.$data.nota, newData)

				if (this.$route.query.view == 'Entrada') {
					this.setBipado(newData.chave, 'Nota')
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		setBipado : function (codigo, tipo) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'recebimento/setBipado',
				headers: {'Content-Type': 'application/json'},
				data: {'idNota': this.nota.id, 'codigo': codigo, 'tipo': tipo}

			}).then(response => {
				Object.assign(this.$data.pesquisa, this.$options.data.apply(this).pesquisa)

				switch (tipo) {
					case 'Nota':
						this.nota.idUsuarioRecebimento = response.data.idUsuarioRecebimento
						this.nota.dataRecebimento = response.data.dataRecebimento
						this.nota.status = response.data.status
					break;
					case 'Produto':
						this.nota.lstItensNotaEletronica = response.data.lstItensNotaEletronica
					break;
					case 'Boleto':
						this.nota.boletos = response.data.boletos
					break;
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		validarNota : function () {
			Swal.fire({
            icon: 'warning',
            title: 'Validar NF-e?',
            text: 'Essa ação é irreversível, caso necessário, adicione observações para registro abaixo.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
				input: 'textarea',
				inputPlaceholder: 'Observações',
            inputValidator: () => {
               if (this.nota.lstItensNotaEletronica.filter(p => !/^[0-9.]+$/.test(p.qtd)).length > 0) {
                  return 'Existem produtos com quantidade inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'recebimento/validarNota',
						headers: {'Content-Type': 'application/json'},
						data: {"id": this.nota.id, "observacao": result.value, 'produtos': this.nota.lstItensNotaEletronica, 'boletos': this.nota.boletos}
						
					}).then(() => {
						this.buscarNota()
		
						this.$toast.fire({
							icon: 'success',
							title: 'Nota validada!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		}
	},
	mounted() {
      this.buscarNota()
	}
}

</script>

<style scoped>

#entrada-tab .nav-link i {
   font-size: 18px;
   width: 21px;
   text-align: center;
}

#entrada-tab .nav-link {
   line-height: 1;
   padding: 14px 20px;
   text-align: start;
   font-weight: 500;
   text-transform: uppercase;
   font-size: 12px;
	width: auto;
	flex: 1 0 0%;
   transition: none !important;
}

#entrada-tab .nav-link * {
   transition: none !important;
}

#entrada-tab,
#entrada-tabContent {
   width: 100%;
}

@media (min-width: 576px) {
   #entrada-tabContent {
      min-height: 210px;
      width: calc(100% - 85px)
   }
   
   #entrada-tab {
      width: 85px;
   }
}

@media (min-width: 1200px) {
   #entrada-tabContent {
      min-height: 210px;
      width: calc(100% - 210px)
   }

   #entrada-tab {
      width: 210px;
   }
}

</style>