<template>
   <div class="col-12 px-0">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-lg-block">
                  <img src="@/assets/images/iconeCodigo.png" alt="icon" @error="imageError" height="45" />
               </div>
               <div class="col-md text-center text-md-start">
                  <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ boleto.codigo == null ? 'Sem código' : boleto.codigo }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ boleto.id }}</span>
                     <span class="ms-3">
                        <i class="far fa-copy font-9 me-1"></i>
                        <span>{{ boleto.numeroDaParcela == null || String(boleto.numeroDaParcela).trim().length == 0 ? '-' : boleto.numeroDaParcela }}</span>
                     </span>
                  </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1"> Vencimento:</strong>
                     <span>{{ String(boleto.dataDeVencimento.dayOfMonth).padStart(2, '0') +'/'+ String(boleto.dataDeVencimento.monthValue).padStart(2, '0') +'/'+ boleto.dataDeVencimento.year }}</span>
                  </p>
               </div>
               <div class="wpx-125 font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <small class="color-theme">R$ </small>{{ parseFloat(boleto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
               </div>
               <div class="wpx-125 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-if="showEdit">
                  <input class="form-check-input" :id="'validacao-check-bol'+ index" type="checkbox" v-model="boleto.validado" :checked="boleto.validado">
                  <label class="cursor-pointer" :for="'validacao-check-bol'+ index" v-if="boleto.validado"><i class="fas fa-check font-11 color-theme me-1"></i>Validado</label>
                  <label class="cursor-pointer" :for="'validacao-check-bol'+ index" v-else><i class="fas fa-times text-red me-1"></i>Não validado</label>
               </div>
               <div class="wpx-125 font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0" v-else>
                  <span v-if="boleto.validado" class="text-green"><i class="far fa-check d-block"></i><small>Validado</small></span>
                  <span v-else class="text-red"><i class="far fa-times d-block"></i><small>Não validado</small></span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Boleto',
   props: ['boleto', 'index', 'showEdit'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>